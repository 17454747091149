import lgBanner1 from "../../Images/eid-sale-pc.png";
import lgBanner2 from "../../Images/clearance banner-pc.png";
import lgBanner3 from "../../Images/flormar new year web cover pc.png";
import lgBanner5 from "../../Images/full-color-Nail-Polish-slider-buy-1-get-2.webp";
import lgBanner8 from "../../Images/HQ banner2.png";
import lgBanner9 from "../../Images/flormar-bag-pc.png";
import lgBanner10 from "../../Images/iphone-campaign-pc.jpg";
import mbBanner1 from "../../Images/eid-sale-mb.png";
import mbBanner2 from "../../Images/clearance banner.png";
import mbBanner3 from "../../Images/flormar new year web cover mobile.png";
import mbBanner5 from "../../Images/Nail-Polish-slider-buy-1-get-1-Mobile.png";
import mbBanner8 from "../../Images/mb-HQ banner2.png";
import mbBanner9 from "../../Images/flormar-bag-mb.png";
import mbBanner10 from "../../Images/iphone-campaign-mb.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import "./Banner.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";
import dayjs from "dayjs";

const Banner = () => {
  const { searchText, setSearchText } = useContext(ThemeContext);
  const banner01 = window.innerWidth > 992 ? lgBanner1 : mbBanner1;
  const banner02 = window.innerWidth > 992 ? lgBanner2 : mbBanner2;
  const banner03 = window.innerWidth > 992 ? lgBanner3 : mbBanner3;
  const banner05 = window.innerWidth > 992 ? lgBanner5 : mbBanner5;
  const banner08 = window.innerWidth > 992 ? lgBanner8 : mbBanner8;
  const banner09 = window.innerWidth > 992 ? lgBanner9 : mbBanner9;
  const banner10 = window.innerWidth > 992 ? lgBanner10 : mbBanner10;
  const navigate = useNavigate();

  const currentDate = dayjs().tz("Asia/Dhaka");

  const offerStartTime = dayjs.tz("2025-02-17 23:59:00", "Asia/Dhaka");
  const offerEndTime = dayjs.tz("2025-03-19 23:59:00", "Asia/Dhaka");

  const eidOfferStartTime = dayjs.tz("2025-03-20 23:59:00", "Asia/Dhaka");
  const eidOfferEndTime = dayjs.tz("2025-03-30 23:59:00", "Asia/Dhaka");

  const offerValidity =
    currentDate.isAfter(offerStartTime) && currentDate.isBefore(offerEndTime);
  const eidOfferValidity =
    currentDate.isAfter(eidOfferStartTime) &&
    currentDate.isBefore(eidOfferEndTime);

  return (
    <div>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={window.innerWidth < 992 ? false : true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={window.innerWidth < 700 ? false : { dynamicBullets: true }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper h-auto"
      >
        {eidOfferValidity && (
          <SwiperSlide>
            <Link to="/shop">
              <img
                className="w-[93vw] rounded-2xl mx-auto h-auto"
                src={banner01}
                alt="sale"
              />
            </Link>
          </SwiperSlide>
        )}

        {offerValidity && (
          <SwiperSlide>
            <Link to="/shop">
              <img
                className="w-[93vw] rounded-2xl mx-auto h-auto"
                src={banner10}
                alt="Eid sale"
              />
            </Link>
          </SwiperSlide>
        )}

        {!eidOfferValidity && (
          <SwiperSlide>
            <Link to="/clearance-Offer">
              <img
                className="w-[93vw] rounded-2xl mx-auto h-auto"
                src={banner02}
                alt="Offers"
              />
            </Link>
          </SwiperSlide>
        )}

        {!eidOfferValidity && (
          <SwiperSlide>
            <Link to="/search/full color nail enamel">
              <img
                className="w-[93vw] rounded-2xl mx-auto h-auto"
                src={banner05}
                alt="Best nail polish"
              />
            </Link>
          </SwiperSlide>
        )}

        <SwiperSlide>
          <a
            href="https://forms.gle/Hms8rAGTDyQNpZsNA"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-[93vw] rounded-2xl mx-auto h-auto"
              src={banner09}
              alt="Offers"
            />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <Link to="/shop">
            <img
              className="w-[93vw] rounded-2xl mx-auto h-auto"
              src={banner08}
              alt="sale"
            />
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Banner;
