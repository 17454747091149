import useScroll from "../../Hooks/useScroll";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
const Accessories = () => {
  const [linkFourHover, setLinkFourHover] = useState(false);
  const [scrollPosition] = useScroll();

    return (
      
        <div
          onMouseEnter={() => setLinkFourHover(true)}
          onMouseLeave={() => setLinkFourHover(false)}
          className={`transition-all ease-in-out duration-300 flex items-center ${
            scrollPosition > 80 ? "min-h-[65px]" : ""
          }`}
        >
          <Link
            to={`/product-category/accessories`}
            className={`mr-10 block font-bold ${
            scrollPosition > 80
              ? "hover:text-accent text-secondary"
              : "text-secondary hover:text-accent"
          }`}
          >
            ACCESSORIES 
            <FontAwesomeIcon
              className="w-3 ms-2"
              icon={faChevronDown}
            ></FontAwesomeIcon>
          </Link>
          <div
            className={`menu-hover flex justify-between ${
              linkFourHover ? "block  text-start" : "hidden relative"
            } ${
              scrollPosition > 80 ? "menu-scrolled" : ""
            } px-4  pb-4 w-[200px] text-black `}
          >
          <div>
          <Link
            to={`/product-category/brush & sponge`}
            className=" hover:text-accent block">Brush & Sponge</Link>
            <Link
            to={`/product-category/makeup brush set`} className=" hover:text-accent block">Makeup Brush Set</Link>
            <Link
            to={`/product-category/eye & brow brush`} className=" hover:text-accent block">Eye & Brow Brush</Link>
            <Link 
            to={`/product-category/makeup applicator`} className=" hover:text-accent block">Makeup Applicator</Link>
            <Link 
            to={`/product-category/face brush`} className=" hover:text-accent block">Face Brush</Link>
            <Link 
            to={`/product-category/artificial nails`} className=" hover:text-accent block">Artificial Nails</Link>
            <Link 
            to={`/product-category/nail art`} className=" hover:text-accent block">Nail Art</Link>
            <Link
            to={`/product-category/manicure set`} className=" hover:text-accent block">Manicure Set</Link>
          </div>
          <div>
            <img src="https://afb801.cdn.akinoncloud.com/cms/2023/01/02/ddf059bb-2149-48fc-ad0a-fb254c89fd6b.jpg" alt=""/>
          </div>
          </div>
        </div>

    );
};

export default Accessories;