import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { ThreeDots } from "react-loader-spinner";
import dayjs from "dayjs";
const OrderConfirmation = () => {
  const confirmationTime = JSON.parse(localStorage.getItem("orderTime"));
  const [confirmationData, setConfirmationData] = useState({});
  // const [loading, setLoading] = useState(true);
  const { loading } = useContext(ThemeContext);
  const { products } = useContext(ThemeContext);

  const currentDate = dayjs().tz("Asia/Dhaka");

  const offerStartTime = dayjs.tz("2025-03-20 23:59:00", "Asia/Dhaka");
  const offerEndTime = dayjs.tz("2025-03-30 23:59:00", "Asia/Dhaka");

  useEffect(() => {
    fetch(
      `https://flormar-web-server-nayem55-flormar-bd.vercel.app/orderConfirmation/${confirmationTime}`
    )
      .then((res) => res.json())
      .then((data) => {
        setConfirmationData(data);
        // setLoading(false);
      });
  }, []);
  let subtotal = 0;
  confirmationData?.items?.forEach((product) => {
    subtotal = subtotal + parseInt(product.total);
  });

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${
    months[new Date(confirmationData?.order_date).getMonth()] || ""
  } ${new Date(confirmationData?.order_date).getDate() || ""}, ${
    new Date(confirmationData?.order_date).getFullYear() || ""
  }`;

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#f34b7f"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center gap-4">
          <div className="border border-secondary border-dotted p-10 my-10 w-[80%] sm:w-[50%]">
            <p className="text-center">
              Thank you. Your order has been received.
            </p>
            {currentDate.isAfter(offerStartTime) &&
            currentDate.isBefore(offerEndTime) ? (
              <p className="text-center font-bold mt-2 text-accent text-xl">
                Your order will be processed after Eid (6th April).
              </p>
            ) : (
              <p className="text-center font-bold mt-2">
                Delivery in 3-5 working days
              </p>
            )}

            {confirmationData.coupon_image && (
              <p className="text-center font-bold mt-2 text-accent text-xl">
                Your order will be processed after varification.
              </p>
            )}
          </div>

          <div className="flex flex-col w-full mb-10 items-center gap-10 sm:flex-row sm:justify-center">
            <div className="text-center">
              <p className="text-secondary text-opacity-70 mb-3">
                Order number:
              </p>
              <p className="font-bold">{confirmationData?.id}</p>
            </div>
            <div className="h-[1px] w-[80%] bg-secondary bg-opacity-30 mx-4 sm:h-[60px] sm:w-[1px]"></div>
            <div className="text-center">
              <p className="text-secondary text-opacity-70 mb-3"> Date:</p>
              <p className="font-bold">{formattedDate}</p>
            </div>
            <div className="h-[1px] w-[80%] bg-secondary bg-opacity-30 mx-4 sm:h-[60px] sm:w-[1px]"></div>
            <div className="text-center">
              <p className="text-secondary text-opacity-70 mb-3"> Total:</p>
              <p className="font-bold">{confirmationData?.total} TK.</p>
            </div>
            <div className="h-[1px] w-[80%] bg-secondary bg-opacity-30 mx-4 sm:h-[60px] sm:w-[1px]"></div>
            <div className="text-center">
              <p className="text-secondary text-opacity-70 mb-3">
                {" "}
                Payment method:
              </p>
              <p className="font-bold">{confirmationData?.payment_method}</p>
              {confirmationData?.set_paid && (
                <p>TransactionId : {confirmationData.transactionId}</p>
              )}
            </div>
          </div>

          <p className="font-bold">ORDER DETAILS</p>
          <div className="w-[100%] px-6 mb-10 flex flex-col gap-2 lg:w-[50%]">
            <div className="flex justify-between mb-4 mt-6">
              <p className="font-bold">PRODUCT</p>
              <p className="font-bold">TOTAL</p>
            </div>
            <hr className="bg-secondary mb-2 text-secondary" />
            <div>
              {confirmationData?.items?.map((order) => (
                <div className="flex flex-col ">
                  <div className="flex justify-between">
                    <p className="w-[70%]">
                      {order?.product_name} x{order?.quantity}
                    </p>
                    <p className="w-[30%] text-right">
                      <span className="text-xs">TK.</span> {order.total}
                    </p>
                  </div>
                  <hr className="bg-secondary my-2 text-secondary" />
                </div>
              ))}
            </div>
            <div className="flex justify-between">
              <p className="">Subtotal:</p>
              <p className="">
                <span className="text-xs">TK.</span> {subtotal}
              </p>
            </div>
            <hr className="bg-secondary my-2 text-secondary" />
            <div className="flex justify-between">
              <p className="">Fixed Cart Discount:</p>
              <p className="">
                <span className="text-xs">TK.</span>{" "}
                {subtotal +
                  parseInt(confirmationData?.shipping_total) -
                  parseInt(confirmationData?.total) || 0}
              </p>
            </div>
            <hr className="bg-secondary my-2 text-secondary" />
            <div className="flex justify-between">
              <p className="">Shipping:</p>
              <p className="">
                <span className="text-xs">TK.</span>{" "}
                {confirmationData?.shipping_total}
              </p>
            </div>
            <hr className="bg-secondary my-2 text-secondary" />
            <div className="flex justify-between">
              <p className="">Payment method:</p>
              <p className=""> {confirmationData?.payment_method}</p>
            </div>
            <hr className="bg-secondary my-2 text-secondary" />
            <div className="flex justify-between mb-10">
              <p className="text-xl">Total:</p>
              <p className="text-xl">
                <span className="text-xs">TK.</span> {confirmationData?.total}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderConfirmation;
