import { ThemeContext } from "../../Contexts/ThemeContext";
import useScroll from "../../Hooks/useScroll";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Eyes = () => {
  const [linkFiveHover, setLinkFiveHover] = useState(false);
  const [scrollPosition] = useScroll();

  return (
    <div
      onMouseEnter={() => setLinkFiveHover(true)}
      onMouseLeave={() => setLinkFiveHover(false)}
      className={`transition-all ease-in-out duration-300 min-h-[50px] flex items-center ${
        scrollPosition > 80 ? "min-h-[65px]" : ""
      }`}
    >
      <Link
        to={`/product-category/eyes`}
        className={`mr-10 block font-bold ${
          scrollPosition > 80
            ? "hover:text-accent text-secondary"
            : "text-secondary hover:text-accent"
        }`}
      >
        EYES
        <FontAwesomeIcon
          className="w-3 ms-2"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </Link>
      <div
        className={`menu-hover flex justify-between block  text-start ${
          linkFiveHover ? "" : "hidden relative"
        } ${
          scrollPosition > 80 ? "menu-scrolled" : ""
        } px-4  pb-4 w-[200px] text-black `}
      >
      <div>
      <Link to={`/product-category/eye Liner`} className=" hover:text-accent block">
          Eye Liner
        </Link>

        <Link to={`/product-category/eye Pencil`} className=" hover:text-accent block">
          Eye Pencil
        </Link>
        <Link to={`/product-category/mascara`} className=" hover:text-accent block">
          Mascara
        </Link>
        <Link to={`/product-category/eye Shadow`} className=" hover:text-accent block">
          Eye Shadow
        </Link>
        <Link to={`/product-category/eye Primer`} className=" hover:text-accent block">
          Eye Primer
        </Link>
        <Link to={`/product-category/pomade`} className=" hover:text-accent block">
          Pomade
        </Link>
      </div>
      <div>
        <img src="https://afb801.cdn.akinoncloud.com/cms/2023/01/02/965b3dc2-24cf-4606-8594-8c512c6d7b8b.jpg" alt=""/>
      </div>
      </div>
    </div>
  );
};

export default Eyes;
