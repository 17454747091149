import { ThemeContext } from "../../Contexts/ThemeContext";
import useScroll from "../../Hooks/useScroll";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Lips = () => {
  const [linkTwoHover, setLinkTwoHover] = useState(false);
  const [scrollPosition] = useScroll();

  return (
    <div
      onMouseEnter={() => setLinkTwoHover(true)}
      onMouseLeave={() => setLinkTwoHover(false)}
      className={`transition-all ease-in-out duration-300 min-h-[50px] flex items-center ${
        scrollPosition > 80 ? "min-h-[65px]" : "min-h-[50px]"
      }`}
    >
      <Link
        to={`/product-category/lips`}
        className={`mr-10 block font-bold ${
          scrollPosition > 80
            ? "hover:text-accent text-secondary"
            : "text-secondary hover:text-accent"
        }`}
      >
        LIPS
        <FontAwesomeIcon
          className="w-3 ms-2"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </Link>
      <div
        className={`menu-hover flex justify-between block  text-start ${
          linkTwoHover ? "" : "hidden relative"
        } ${
          scrollPosition > 80 ? "menu-scrolled" : ""
        } px-4  pb-4 w-[200px] text-black `}
      >
        <div>
          <Link to={`/product-category/lip care`} className=" hover:text-accent block">
            Lip Care
          </Link>
          <Link to={`/product-category/lip gloss`} className=" hover:text-accent block">
            Lip Gloss
          </Link>
          <Link
            to={`/product-category/lip pencil`}
            className=" hover:text-accent block"
          >
            Lip Pencil
          </Link>
          <Link to={`/product-category/lipstick`} className=" hover:text-accent block">
            Lip Stick
          </Link>
        </div>
        <div>
          <img
            src="https://afb801.cdn.akinoncloud.com/cms/2023/01/02/d1a5d981-6c84-492f-8855-766c00526bad.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Lips;
